<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--기간-->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="심사기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 피심사부서 -->
          <c-dept 
            type="search" 
            label="LBL0010133" 
            name="deptCd" 
            :isFirstValue="false"
            v-model="searchParam.deptCd" />
        </div>
      </template>
    </c-search-box>
    <!--안전보건 내부심사 결과서 목록-->
    <c-table
      ref="table"
      title=""
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-result',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
        isResult: 'Y',
        period: [],
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'saiInternalActionId' },
          { index: 1, colName: 'saiInternalActionId' },
          { index: 2, colName: 'saiInternalActionId' },
          { index: 3, colName: 'saiInternalActionId' },
          { index: 4, colName: 'saiInternalActionId' },
          { index: 5, colName: 'saiInternalActionTargetDeptId' },
          { index: 6, colName: 'saiInternalActionTargetDeptId' },
          { index: 7, colName: 'saiInternalActionTargetDeptId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionPeriod',
            field: 'actionPeriod',
            //심사기간
            label: 'LBL0010115',
            align: 'center',
            style: 'width:220px',
            sortable: true,
          },
          {
            name: 'actionName',
            field: 'actionName',
            //수행계획서명
            label: 'LBL0010116',
            align: 'left',
            sortable: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            //검토자
            label: 'LBLREVIEWER',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            //승인자
            label: 'LBLAPPROVER',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'deptName',
            field: 'deptName',
            //피심사팀
            label: 'LBL0010111',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'saiInternalActionTargetDeptStepName',
            field: 'saiInternalActionTargetDeptStepName',
            label: '통합심사상태',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'managerUserName',
            field: 'managerUserName',
            label: '심사팀장',
            align: 'center',
            style: 'width:200px',
            sortable: true,
            type: 'link'
          },
          {
            name: 'teamUserName',
            field: 'teamUserName',
            label: '심사자/부서',
            align: 'center',
            style: 'width:200px',
            sortable: true,
            type: 'link'
          },
          {
            name: 'internalAuditResultStatusName',
            field: 'internalAuditResultStatusName',
            label: '심사자/부서 진행상태',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.internal.targetDept.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      this.openPop(row, col);
    },
    openPop(row, col) {
      if(col.name === 'managerUserName') { // 심사팀장 클릭
        if (this.editable || row.managerUserId == this.$store.getters.user.userId) { // 내부심사 통합결과 확인(심사팀장)
          this.popupOptions.title = '내부심사 ('+row.deptName+') 심사 결과 상세';   //내부심사 결과서 상세
          this.popupOptions.param = {
            saiInternalActionTargetDeptId: row ? row.saiInternalActionTargetDeptId : '',
          };
          this.popupOptions.target = () => import(`${'./internalResultDetail.vue'}`);
          this.popupOptions.visible = true;
          this.popupOptions.closeCallback = this.closePopup;
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '피심사팀별 내부심사 결과를 확인할 권한이 없습니다.', // 제외할 행을 지정하세요.
            type: 'warning', // success / info / warning / error
          });
        }
      } else if (col.name === 'teamUserName') {
        if (this.editable) {
          this.popupOptions.title = '피심사팀('+row.deptName+') 심사 상세';   //내부심사 결과서 상세
          this.popupOptions.param = {
            saiInternalActionTargetDeptId: row ? row.saiInternalActionTargetDeptId : '',
            saiInternalActionId: row.saiInternalActionId,
            deptCd: row.deptCd,
            teamDeptCd: row.teamDeptCd,
            reviewUserId: row.reviewUserId,
          };
          this.popupOptions.target = () => import(`${'./internalResultChecklistDept.vue'}`);
          this.popupOptions.visible = true;
          this.popupOptions.closeCallback = this.closePopup;
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '심사자의 부서원들만 접근할 수 있습니다.', // 제외할 행을 지정하세요.
            type: 'warning', // success / info / warning / error
          });
        }
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
